import React from "react";
import Layout from "../../components/layout";
import WhyGrootan from "../../components/views/company/why-grootan";

const WhyGrootanPage = () => {
  return (
    <Layout>
      <WhyGrootan />
    </Layout>
  );
};

export default WhyGrootanPage;
