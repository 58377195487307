import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

const PartnerBox = ({ partnerBoxData }) => {
  const { partnerImage, partnerTagLine, partnerType, partnerLocation } = partnerBoxData;
  const createdImage = getImage(partnerImage);
  return (
    <li>
        <GatsbyImage
        image={createdImage}
        className="img-responsive partner-image"
        alt={`partner image`}
        />
        <ReactMarkdown
            source={partnerTagLine}
            className="lead font-regular font-weight-normal partner-tagline"
        />
        <div className="partner-tags">
            <span className="partner-type">{partnerType}</span>
            <span className="partner-location">{partnerLocation}</span>
        </div>
    </li>
  );
};

export default PartnerBox;
