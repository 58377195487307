import React from "react";
import HeaderBackground from "../../../common/HeaderBackground";
import AboutSection from "../../../common/AboutSection";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import ClientBox from "../../../views/home/ClientBox";
import PartnerBox from "../../../views/home/PartnerBox";
import MetaData from "../../../common/MetaData";
import { metaData } from "../../../../config"
const WhyGrootan = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiWhyGrootanPages {
        nodes {
          title
          titleSlogan
          description
          aboutTitle
          aboutDescription
          sectionsList {
            sectionTitle
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: NONE, width: 540)
              }
            }
          }
          partnerTitle
        }
      }
      allStrapiHomeBuiltteches {
        nodes {
          description
          title
          clientsList {
            link
            image {
              childImageSharp {
                gatsbyImageData(quality: 10, width: 200, placeholder: NONE)
              }
            }
          }
          partnersList {
            partnerImage {
              childImageSharp {
                gatsbyImageData(quality: 10, width: 200, placeholder: NONE)
              }
            }
            partnerTagLine
            partnerType
            partnerLocation
          }
        }
      }
    }
  `);

  const {
    title,
    description,
    partnerTitle,
    aboutTitle,
    aboutDescription,
    sectionsList
  } = data.allStrapiWhyGrootanPages.nodes[0];
  const { clientsList, partnersList } = data.allStrapiHomeBuiltteches.nodes[0];

  return (
    <>
      <MetaData pageTitle={title}  description={metaData.whygrootan.description} keyword={metaData.whygrootan.keyword} />
      <section className="section position-relative pt-6 pb-2">
        <HeaderBackground backgroundClass={"why-Grootan-banner-bg"} />
        <div className="container overflow-hidden h-100 mt-4">
          <div className="row">
            <div className="col-md-10 col-lg-10 py-4 m-auto text-center">
              <h1 className="display-4 text-contrast bold">
                {title}{" "}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="section built-tech">
        <div className="container py-5">
          <div className="section-heading mb-2">
            <ReactMarkdown
              source={description}
              className="lead font-regular font-weight-normal"
            />
          </div>
          <div className="video-whatwedo mt-4">
            <video width={854} height={480} poster={'/video/what-we-do-startup-india.jpg'} controls>
              <source src="/video/grootan-startup-india.mp4" type="video/mp4"/>
            </video>
          </div>
          <div className="section-heading mt-6 mb-1 text-center">
            <h2><strong>Our Clients</strong></h2>
          </div>
          <div className="row">
            <div className="client-logo">
              <ul id="our-partners" >
                {clientsList.map((clientBoxData, index) => (
                  <ClientBox key={index} clientBoxData={clientBoxData} />
                ))}
              </ul>
            </div>
          </div>
          <div className="section-heading mb-2">
            <ReactMarkdown
              source={partnerTitle}
              className="lead font-regular font-weight-normal"
            />
          </div>
          <div className="row">
            <div className="partner-logo">
              <ul>
              {partnersList.map((partnerBoxData, index) => (
                  <PartnerBox key={index} partnerBoxData={partnerBoxData} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <AboutSection
        aboutData={{ aboutTitle, aboutDescription, sectionsList }}
      />
    </>
  );
};

export default WhyGrootan;
