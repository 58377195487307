import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ClientBox = ({ clientBoxData }) => {
  const { image, link } = clientBoxData;
  const createdImage = getImage(image);
  return (
    <li>
      <a href={link} target="_blank" rel="noreferrer noopener">
          <GatsbyImage
            image={createdImage}
            className="img-responsive client-image"
            alt={`${link} client image`}
          />
      </a>
    </li>
  );
};

export default ClientBox;
